'use strict'
{api, build} = require 'config'
{mock_base_url} = require 'environment'

serialize = (data) ->
  result = []
  for key, value of data
    result.push "#{encodeURIComponent(key)}=#{encodeURIComponent(value)}"
  result.join('&')

serializeObj = (obj, prefix) ->
  str = []

  type = Object.prototype.toString.call(obj)
  for p, v of obj
    if obj.hasOwnProperty(p)
      k = if prefix then prefix + "[" + (if type is '[object Object]' then p else '') + "]" else p
      str.push(if (v isnt null and typeof v is "object") then serializeObj(v, k) else encodeURIComponent(k) + "=" + encodeURIComponent(v))

  str.join("&")

request =
  head: (url, data) ->
    url += '.json' if api.type is 'local'

    if data
      url += if url.includes('?') then '&' else '?'
      url += serialize(data)

    return this.send(url, {method: 'HEAD'})

  get: (url, data, opts) ->
    url += '.json' if api.type is 'local'

    if data
      url += if url.includes('?') then '&' else '?'
      if opts?.serializeObject
        url += serializeObj(data)
      else
        url += serialize(data)

    @send(url, null, opts)

  adjustHeaders: (headers) ->
    headers ?= {}
    if build?.type == 'mobile'
      {platform, version, code} = build
      code = if code then ".#{code}" else ''
      {capitalize} = require './utils'
      headers['X-App-Version'] = "#{capitalize(platform)}, #{version}#{code}"
    return headers

  post: (url, args, opts) ->
    params =
      method: 'POST'
      headers:
        'Accept': 'application/json'
        'Content-Type': 'application/json'
      body: JSON.stringify args

    @send url, params, opts

  put: (url, data, opts) ->
    params =
      method: 'PUT'
      headers:
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      body: JSON.stringify(data)

    @send url, params, opts

  patch: (url, data, opts) ->
    params =
      method: 'PATCH'
      headers:
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      body: JSON.stringify(data)

    @send url, params, opts

  delete: (url, data, opts={}) ->
    params =
      method: 'DELETE'

    if data
      if opts.json?
        params.headers = {
          'Accept': 'application/json'
          'Content-Type': 'application/json'
        }
        params.body = JSON.stringify(data)
      else
        params.headers = {}
        # params.headers['Accept'] = 'application/json, text/javascript, */*; q=0.01'
        # params.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        body = new FormData
        for key, value of data
          body.append(key, value)
        params.body = body

    @send url, params, opts

  send: (url, params={}, opts={}) ->
    url = url.replace(/^\//, '')

    if opts.mock and mock_base_url
      url = "#{mock_base_url}#{url}"
    else unless url.startsWith("http")
      url = "#{api.hostname}#{url}"

    params.headers = @adjustHeaders(params.headers)

    if opts.mock && opts.mock.status
      params.headers['Prefer'] = "status=#{opts.mock.status}"

    params.credentials = 'include'

    return fetch(url, params).then((response) ->
      return response if opts.return_response

      if /text\/html/.test(response.headers.get('Content-Type'))
        throw response

      if response.ok
        if params.method == 'HEAD'
          return response

        json =
          if response.status == 204
            {}
          else
            if /text\/plain/.test(response.headers.get('Content-Type'))
              response.text()
            else
              response.json()

        return if opts.return_headers then headers: response.headers, json: json else json

      if /checkin\/auth/.test(response.url)
        require('stores/app').actions.navTo('/checkin/auth')

      authStore = require('stores/auth')
      user = authStore.get('user')
      if user.id && response.status == 401 && !/(session|checkin\/pin)/.test(response.url)
        console.log('Unauthorized, signing out')
        authStore.actions.signout()
      if typeof response.json == 'function'
        response.json().then (err) ->
          response.error = err.error
          response.error_body = err
          response.field = err.field
          throw response
      else
        Promise.reject response
      )

  serialize: serialize

module.exports = request
