{appStore, authStore, messagesStore, medicationsStore, patientStore} = require 'stores'
{fa, main, section, div, p, span, strong, ul, li, a, hr} = Exim.DOM
newAuthStore = require('stores/new-auth')
screeningStore = require('stores/screening')
synopsisStore = require('stores/new-synopsis')
oldSynopsisStore = require('stores/synopsis')
wellnessStore = require('stores/wellness').default
{client} = require 'config'
{splitTransitionTileInfo} = require 'lib/utils'
getFocusableElements = require('lib/utils/getFocusableElements').default

Widget         = require './Widget'
WhatsNewWidget = require './WhatsNewWidget'
WellnessProgramWidget = require './WellnessProgramWidget'
UrgentNoticeWidget = require './UrgentNoticeWidget'
Footer         = require 'components/app/Footer'
PullToRefresh  = require 'components/elements/PullToRefresh'
PatientHeader  = require 'components/elements/PatientHeader'
PatientSearchHeader = require 'components/elements/patient-search-header'
Tooltip             = require 'components/elements/Tooltip'
{cx} = Exim.helpers

Landing = Exim.createView module.id,
  mixins: [
    messagesStore.connect('unreadCount', 'messagesFetching')
    authStore.connect('user')
    oldSynopsisStore.connect('clinics')
  ]

  listen: [
    'patient/loginStats'
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  componentWillMount: ->
    { preferred_name, id, isAdmin } = authStore.get('user')
    @refresh()

    appStore.actions.setHeader(name: "Welcome, #{preferred_name}")
    !isAdmin && wellnessStore.fetchActivePrograms(id)

  componentDidMount: ->
    appStore.actions.setTitle('Landing')
    screeningStore.fetchHealthScreenings(newAuthStore?.user?.patientId)
    newAuthStore.fetchFeatureFlags()
    @setState timeInterval: setInterval(@setTimeDiffString, 60000)
    setTimeout(() ->
      links = document.getElementById('sidebar')
      focusable = getFocusableElements(links)
      focusable[0]?.focus()
    , 0)
    {ef_id, ctm, first_name, last_name, email, xoid, cell_phone, home_phone} = authStore.get('user')
    if window.utag and xoid
      window.utag.view({
        page_name: "Crossover Health",
        platform: "Portal",
        customer_type: "",
        customer_efid: ef_id,
        customer_class: if ctm then "ctm" else "member",
        customer_state: "",
        customer_postal_code: "",
        customer_last_name: last_name,
        customer_first_name: first_name,
        customer_email: email,
        customer_id: xoid,
        customer_country: "",
        customer_phone_number: cell_phone || home_phone || "",
        user_logged_in: "1"
      })

  componentWillUnmount: ->
    appStore.actions.setTitle()
    clearInterval(@state.timeInterval)

  refresh: ->
    messagesStore.actions.fetchMessages('received', 1)
    patientStore.actions.fetchLoginStats()

  getInitialState: ->
    whatsNewDismissed: localStorage?.getItem('whatsNewDismissed') or false
    loginHover: false
    timeDiffStr: ''

  goTo: (pathname) -> (e) =>
    e.preventDefault()
    @context.router.push pathname

  linkToWellnessCenter: () -> () =>
    { employer, ef_id } = authStore.get('user')
    window.open("https://be-well.crossoverhealth.com/?utm_source=portal&utm_campaign=be_well&utm_medium=tile&utm_term=#{employer}+#{ef_id}", "_blank")

  dismiss: ->
    try
      localStorage?.setItem('whatsNewDismissed', true)
    catch err

    @setState whatsNewDismissed: true

  widgetAllowed: (key)->
    {user} = @state
    isProxying = user.proxying
    isAdmin    = user.admin
    isTraveler = user.traveler
    jail       = user.jail
    isCtm      = user.ctm
    site       = user.default_site
    efId       = user.ef_id

    restrictions = {
      trends:      !(jail &  1) and (isProxying or !isAdmin)
      schedule:    !(jail &  2)
      appointments:if isAdmin or isProxying then !(jail & 4) else !isTraveler
      messages:    !(jail &  8)
      medications: !(jail & 16) and (site != 'crk' and site != 'amk' and site != 'inn')
      laboratories:!(jail & 32) and (site in ['ast','cpt','scv','sac','prk','wfe','mtv','stm','mld','fst','sma','red','eye','mtc','vfc','gop','mil','cos','nyc','rgs','mfh','bee','hpo','glo','nbp','amp','atx','spr','lcs','mpk','kds','ahc','asg','asd','asv','apm','ats','apt','alr','alj','als','awl','aro','abt','asb','afd','aev','xct','xow','xoe','xne'])
      documents:   !(jail & 64)
      beWell:      !(jail & 128) and !isCtm and !isAdmin and synopsisStore["be_well"]
      closure:     (efId in [133, 134, 163, 164, 258, 259, 101])
    }

    restrictions[key]

  setLoginHover: (value) ->
    return (evt) =>
      @setState loginHover: value

  closureWidget: ->
    return null unless @widgetAllowed('closure')

    Widget
      icon: 'warning'
      title: 'Important Notice'
      key: 'closure-wdgt'
      wrapperClassName: 'Closure-wrapper'
      body: ->
        div null,
            p className: 'Closure-text',
              "Life@ Wellness Center on Willow Campus (MPK 49) will be permanently closed as of 5/21/22."
            p className: 'Closure-text',
              "For appointments after that date, please schedule at the Life@ Wellness Center on Chilco Campus (MPK 281)."

  messagesWidget: ->
    return null unless @widgetAllowed('messages')
    {unreadCount} = @state

    secondary_ct = "You have #{unreadCount or 0} unread message#{if +unreadCount is 1 then '' else 's'}"

    {default_site} = authStore.get('user')
    {contact} = clinic = @state.clinics[default_site] or {}

    Widget
      icon: 'envelope'
      title: 'Messages'
      key: 'messages-wdgt'
      body: ->
        unless (client == "apple")
          p null,
            strong {}, "Start here. "
            "Many health issues and questions can be managed by messaging with your care team."
        else
          p "Start here if you need to send a non-urgent message to your Care Team. If your need is urgent, please call us at #{contact}.",
      actions: [
        {name: 'Send a message', handler: @goTo('/messages/new'), primary: true}
        {name: secondary_ct,handler: @goTo('/messages/unread')}
      ]

  counselingWidget: ->
    return null unless client == 'microsoft'

    Widget
      icon: 'calendar-o'
      title: 'Counseling'
      key: 'counseling-wdgt'
      body: ->
        p null,
          span "Find short-term counseling and mental health support through Spring Health. Create an account on "
            a href: "https://benefits.springhealth.com/microsoft/", className: "LandingWidgetBody-more", "Spring Health"
          span " to learn more, schedule a therapy appointment, or connect with your Spring Health Care Navigator."

  virtualAppointmentsWidget: ->
    return null unless @widgetAllowed('appointments')

    {user} = newAuthStore
    {default_site} = authStore.get('user')
    isSingapore = default_site in ['amk', 'inn']

    return [] if !user.isEligible
    return if client == "apple" and !isSingapore

    Widget
      icon: 'video-camera'
      title: 'Virtual Visit'
      ref: 'virtualappointments'
      key: 'virtual-appts-wdgt'
      body: ->
        p null,
          strong {}, "For your convenience and safety "
          "virtual visits are encouraged during the COVID-19 pandemic. Most issues can be resolved remotely. If needed, your provider may #{if client == 'microsoft' then 'recommend' else 'schedule'} an in-person follow up visit."

      actions: [
        {name: 'Schedule a virtual visit', handler: @goTo({pathname: '/appointments/new', search: '?search_type=virtual_method'})}
        {name: 'Manage appointments', handler: @goTo('/appointments')}
      ]

  wellnessCenterRatesWidget: ->
    {default_site} = authStore.get('user')
    isCork = default_site is 'crk'

    return unless client == "apple" || client == "cork"

    currentYear = moment().format("YYYY")
    Widget
      icon: "exclamation-triangle"
      title: "2025 Wellness Center Rates"
      ref: "wellnesscenterrates"
      key: "wellness-center-rates-wdgt"
      body: ->
        div className: "WellnessCenterRates-wrapper",
          unless isCork
            p null,
              span "Learn more about the 2025 Apple Wellness Center rates "
              strong {},
                a target: "_blank", href: "https://people.apple.com/IE/en/content/10038#tab-reference-112495-eligibility-and-cost-4", className: "WellnessCenterRates-link", "here"
              span "."
          else
            p null,
              span "Learn more about the #{currentYear} Cork Wellness Center rates "
              strong {},
                a target: "_blank", href: "https://s3.us-west-1.amazonaws.com/public.crossoverhealth.com/apple/2023_CORK_Wellness_Center_Rates.pdf", className: "WellnessCenterRates-link", "here"
              span "."

  officeAppointmentsWidget: ->
    return null unless @widgetAllowed('appointments')
    {user} = newAuthStore
    return @ineligibleAppointmentsWidget() if !user.isEligible

    Widget
      icon: 'calendar-o'
      title: 'Office Visit'
      ref: 'officeappointments'
      key: 'office-appts-wdgt'
      body: ->
        unless (client == "apple")
          p null,
            strong {}, "Sometimes, "
            "an in-person visit may be the best option. However, virtual visits are encouraged during the COVID-19 pandemic, especially if you have COVID-19 symptoms. If you're uncertain whether an office visit is the best option, start by scheduling a phone consultation."
        else
           p "Other health concerns require an office visit with your provider."
      actions:
        if synopsisStore.hideLandingPageOfficeBooking
          []
        else
          [
            {name: 'Schedule an office visit', handler: @goTo({pathname: '/appointments/new'})}
            {name: 'Manage appointments', handler: @goTo('/appointments')}
          ]

  ineligibleAppointmentsWidget: ->
    Widget
      icon: 'calendar-o'
      title: 'Appointments'
      ref: 'appointments'
      key: 'appts-wdgt'
      actions: [
        {name: 'My Appointments', handler: @goTo('/appointments')}
      ]

  medsWidget: ->
    return null unless @widgetAllowed('meds')
    Widget
      icon: 'flask'
      title: 'Medications'
      key: 'meds-wdgt'
      actions: [
        {name: 'My medications', handler: @goTo('/medications')}
      ]

  urgentNoticeWidget: ->
    unless (client == "apple" || client == "cork")
      UrgentNoticeWidget { key: 'urgentNotice-wdgt' }
    else
      null

  whatsNewWidget: ->
    {admin} = authStore.get('user')

    unless @state.whatsNewDismissed || ((client == "apple" || client == "cork") && !admin)
      WhatsNewWidget {@dismiss, key: 'whatsNew-wdgt'}
    else
      null

  wellnessLandingWidget: ->
    {user} = newAuthStore

    if (user.canViewWellnessWidget)
      programsWithWidgets = wellnessStore.programsWithWidgets
      WellnessProgramWidget { key: 'wellness-wdgt', programsWithWidgets }
    else
      null

  scheduleWidget: ->
    return null unless @widgetAllowed('schedule')
    Widget
      icon: 'calendar-o'
      title: 'Daily Schedule'
      ref: 'schedule'
      key: 'schedule-wdgt'
      actions: [
        {name: 'View Appointments', handler: @goTo('/schedule')}
      ]

  setTimeDiffString:  ->
    {loginStats} = @state
    return unless loginStats
    timeDiff = moment(loginStats.last_activity_at).fromNow()
    @setState timeDiffStr: "Time since last activity: #{timeDiff}"

  beWellWidget: ->
    return null unless @widgetAllowed('beWell')
    Widget
      icon: 'smile-o'
      title: 'Be Well'
      ref: 'beWell'
      key: 'beWell-wdgt'
      actionClassName: 'BeWell-cta'
      body: ->
        div null,
          p "Get the most out of your membership. Crossover providers have built a place for members to engage in good health—and you’ve got open access. You can get a free fitness assessment, take classes for exercise, nutrition or stress, play videos or podcasts to learn about topics you care about, and use our tools for a healthy lifestyle."
      actions: [
          {name: 'Take a look', handler: @linkToWellnessCenter() , primary: true, icon: "external-link"}
      ]

  memberTransitionTile: (transitionTileInfo) ->
    { headerText, body, faIcon } = splitTransitionTileInfo(transitionTileInfo)
    Widget
      icon: faIcon
      title: headerText
      ref: 'memberTransition'
      key: 'memberTransition-wdgt'
      body: ->
        div { dangerouslySetInnerHTML: {__html: body } }

  getRetrofitDisplay: ->
    transitionTileInfo = synopsisStore?.transitionBannerInfo?.tile

    userWidgets = []
    fixedWidgets = []

    if transitionTileInfo
      return { userWidgets: [@memberTransitionTile(transitionTileInfo)], fixedWidgets: [] }

    return { userWidgets, fixedWidgets }

  getWidgetsToDisplay: ->
    {admin, proxying} = authStore.get('user')
    {hasPortalWellnessTile, featureFlagsLoaded} = newAuthStore

    userWidgets = []
    fixedWidgets = []

    if admin
      userWidgets = [@scheduleWidget(), @messagesWidget()]
      fixedWidgets = [@beWellWidget(), @urgentNoticeWidget(), @whatsNewWidget()]
    else if featureFlagsLoaded
      if hasPortalWellnessTile and !proxying
        userWidgets = [@wellnessLandingWidget()]
      else
        fixedWidgets = [@beWellWidget(), @urgentNoticeWidget(), @whatsNewWidget()]
        userWidgets = [
          @closureWidget()
          @messagesWidget()
          @virtualAppointmentsWidget()
          @wellnessCenterRatesWidget()
          @officeAppointmentsWidget()
          @counselingWidget()
          @medsWidget()
          @wellnessLandingWidget()
        ]

    return { userWidgets, fixedWidgets }

  render: ->
    {retrofit_signin_message} = authStore.get('user')
    {loginStats, timeDiffStr} = @state
    isRetroLoading = retrofit_signin_message && oldSynopsisStore.get('synopsisLoading')
    loading = messagesStore.get('messagesFetching') || isRetroLoading
    {userWidgets, fixedWidgets} = if retrofit_signin_message then @getRetrofitDisplay() else @getWidgetsToDisplay()

    main id: 'mainContent', className: "Content #{cx 'Content--withPatientHeader': @state.user.proxying}",
      PullToRefresh tag: section, className: 'Content-body', isLoading: loading, onRefresh: @refresh,
        div className: 'ContentInnerWrapper Landing',
          PatientHeader()
          div className: 'ContentInner',
            React.createElement(PatientSearchHeader)
            div className: 'LandingWidgets',
              if userWidgets.length
                div className: 'LandingWidgetsColumn LandingWidgetsColumn--left',
                  userWidgets
              div className: 'LandingWidgetsColumn LandingWidgetsColumn--right',
                fixedWidgets
              if loginStats
                div className: 'ContentLoginStats',
                  span className: 'ContentLoginStats-lastActivity',
                    timeDiffStr or "Time since last activity: #{moment(loginStats.last_activity_at).fromNow()}"
                  if loginStats.failed_logins and loginStats.failed_logins > 0
                    failed_warning = if loginStats.failed_logins is 1 then "Unsuccessful log-on attempt " else "Unsuccessful log-on attempts "
                    span className: 'ContentLoginStats-loginAttempts', tabIndex: 0, onMouseEnter: @setLoginHover(true), onMouseLeave: @setLoginHover(false), onFocus: @setLoginHover(true), onBlur: @setLoginHover(false),
                      "#{loginStats.failed_logins} #{failed_warning} ",
                      Tooltip({ type: 'text', text: "You may be receiving this if you entered your password incorrectly. If you suspect malicious activity, contact HQ."}) if @state.loginHover
                      fa 'info-circle'

          Footer()

module.exports = Landing
